<template>
<div id="chart">
    <v-card>
        <v-card-title class="pa-4 info">
            <span class="title white--text">LISTE DES COLLECTES DES DONNEES AU NIVEAU DES PLUVIOMETRES</span>
        </v-card-title>

        <v-card-text>

            <v-container>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select :items="listdepartement" @change="fuseSearchdepartement" outlined hide-details item-value="id" v-model="selectdepartement" label="Departement *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="listcommune" @change="fuseSearchcommune" outlined hide-details item-value="id" v-model="selectcommune" label="Commune *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="null">
                                <v-select :items="listarrondissement" @change="fuseSearcharrondissement" outlined hide-details item-value="id" v-model="selectarrondissement" label="Arrondissement *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>

                                </v-select>
                            </v-col>

                            <v-col cols="12" sm="6" v-if="null">
                                <v-select :items="listvillage" outlined hide-details item-value="id" v-model="selectvillage" label="Village/Ville *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>

                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">

                        <v-row>

                            <v-col cols="12" sm="6">
                                <v-select :items="listannee" outlined hide-details v-model="selectannee" label="Annee *">

                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="null">
                                <v-select :items="listtrimestre" outlined hide-details v-model="selecttrimestre" @change="fuseSearchtrimestre" label="Trimestre *">

                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="null">
                                <v-select :items="listmois" outlined hide-details v-model="selectmois" label="Mois *">

                                </v-select>
                            </v-col>

                            <v-col cols="12" sm="6" v-if="null">
                                <v-select :items="listpluviometre" outlined hide-details item-value="id" v-model="selectpluviometre" label="List des pluviometres *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>

                        </v-row>
                    </v-col>

                </v-row>

            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" dark @click="dialog=false">Annuler</v-btn>
            <v-btn color="success" dark @click="recherche">Recherche</v-btn>
        </v-card-actions>
    </v-card>
    <span class="title">Graphe</span>
    
      <v-row>
        <v-col cols="12" sm="6">
      
        <v-card>
        <apexchart type="line" height="400" :options="chartOptions" :series="series" class="wrap"></apexchart>  
      
      </v-card>
    </v-col>
      <v-col cols="12" sm="6">
      <v-card>
     
        <apexchart type="line" height="400" :options="chartOptions2" :series="series2" class="wrap"></apexchart>  
      
      </v-card>
      </v-col>
      <v-col cols="12">
      <v-card>
      
        <apexchart type="line" height="400" :options="chartOptions3" :series="series3" class="wrap"></apexchart>  
      
      </v-card>
    </v-col>
      </v-row>
        
    
</div>
</template>

<script>
import api from '@/serviceApi/apiService'
export default {

    data() {
        return {
            series: [],
            series2: [],
            series3: [],

            chartOptions: {},
            chartOptions2: {},
            chartOptions3: {},

            /* chartOptions: {
                 chart: {
                     height: 400,
                     type: 'line',
                     group: 'social',
                     dropShadow: {
                         enabled: true,
                         color: '#000',
                         top: 18,
                         left: 7,
                         blur: 10,
                         opacity: 0.2
                     },
                     toolbar: {
                         show: true,
                     }
                 },
                 annotations: {
               yaxis: [{
                 y: 20,
                 borderColor: '#00E396',
                 label: {
                   borderColor: '#00E396',
                   style: {
                     color: '#fff',
                     background: '#00E396',
                   },
                   text: 'Support',
                 }
               }, {
                 y: 5,
                 y2: 12,
                 borderColor: '#000',
                 fillColor: '#FEB019',
                 opacity: 0.2,
                 label: {
                   borderColor: '#333',
                   style: {
                     fontSize: '10px',
                     color: '#333',
                     background: '#FEB019',
                   },
                   text: 'Y-axis range',
                 }
               }],
               xaxis: [{
                 x: new Date('23 Nov 2017').getTime(),
                 strokeDashArray: 0,
                 borderColor: '#775DD0',
                 label: {
                   borderColor: '#775DD0',
                   style: {
                     color: '#fff',
                     background: '#775DD0',
                   },
                   text: 'Anno Test',
                 }
               }, {
                 x: new Date('26 Nov 2017').getTime(),
                 x2: new Date('28 Nov 2017').getTime(),
                 fillColor: '#B3F7CA',
                 opacity: 0.4,
                 label: {
                   borderColor: '#B3F7CA',
                   style: {
                     fontSize: '10px',
                     color: '#fff',
                     background: '#00E396',
                   },
                   offsetY: -10,
                   text: 'X-axis range',
                 }
               }],
               points: [

               ]
             },
                 colors: ['#77B6EA', '#545454'],
                 dataLabels: {
                     enabled: true,
                 },
                 stroke: {
                     curve: 'smooth'
                 },
                 title: {
                     text: 'Pluviometre',
                     align: 'centre',
                     style: {
                         fontSize: '20px',
                         color: '#666'
                     }
                 },
                 grid: {
                     borderColor: '#e7e7e7',
                     row: {
                         colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                         opacity: 0.5
                     },
                 },
                 markers: {
                     size: 1
                 },
                 xaxis: {
                     categories: ['JANVIER', 'FEVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOUT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DECEMBRE'],
                     title: {
                         text: 'Mois'
                     }
                 },
                 yaxis: {
                     title: {
                         text: 'Hauteur(mm) ',
                         style: {
                             fontSize: '20px',
                             color: '#666'
                         }

                     },
                     // min: 5,
                     // max: 40
                 },
                 legend: {
                     position: 'top',
                     horizontalAlign: 'right',
                     floating: true,
                     offsetY: -25,
                     offsetX: -5
                 }
             },*/

            listdepartement: [],
            listcommune: [],
            listarrondissement: [],
            listvillage: [],

            listcommune2: [],
            listarrondissement2: [],
            listvillage2: [],

            listpluviometre: [],

            listannee: [],
            listmois: [],
            listmois2: [],
            listtrimestre: [],

            selectdepartement: '',
            selectcommune: '',
            selectarrondissement: '',
            selectvillage: '',
            selectdirection: '',
            selectprofil: '',
            selectannee: '',
            selectmois: '',
            selecttrimestre: '',
            selectpluviometre: '',
            min: 0,
            max: 0,

        }
    },

    created() {

        // this.readAll();
        this.readAll_globale_localisation();

    },

    methods: {
        readAll_globale_localisation: async function () {
            const data = await api.readAll('admin-backoffice/globale-localisation');
            this.listdepartement = data.departement;
            this.listcommune2 = data.commune;
            this.listarrondissement2 = data.arrondissement;
            this.listvillage2 = data.village;
            this.listdirection = data.ddaep;

            let fd = new FormData();
            const data2 = await api.createUpdatedata('admin-backoffice/all-filtre', fd);
            this.listannee = data2.annee;
            this.listmois2 = data2.mois;
            this.listtrimestre = data2.trimestre;

            let fd2 = new FormData();
            const data3 = await api.createUpdatedata('admin-backoffice/all-pluviometre', fd2);
            this.listpluviometre = data3.succes;

        },

        readAll: async function () {

            console.log('commune : ' + this.selectcommune);
            console.log('arrondissement : ' + this.selectarrondissement);
            console.log('village : ' + this.selectvillage);

            let fd = new FormData();
            fd.append('id_communes', this.selectcommune);
            fd.append('id_arrondissements', this.selectarrondissement);
            fd.append('id_villages', this.selectvillage);
            fd.append('id_departements', this.selectdepartement);
            fd.append('id_pluviometre', this.selectpluviometre);
            fd.append('annee', this.selectannee);
            fd.append('mois', this.selectmois);
            fd.append('trimestre', this.selecttrimestre);
            //fd.append('niveau');
            const data = await api.createUpdatedata('admin-backoffice/statistique-graphe', fd);

            this.series = [
                {
                    name: "CUMUL HAUTEUR DE PLUIE",
                    data: data.cummule
                },
              
            ];
            this.series2 = [
                {
                    name: "CUMUL NOMRE DE JOURS DE PLUIE",
                    data: data.nombre_jour
                },
              
            ];
            this.series3 = [
                {
                    name: "CUMUL MOYENNE HAUTEUR DE PLUIE",
                    data: data.moyenne
                },
              
            ];

            console.log(this.series);
            console.log(this.series2);
            console.log(this.series3);

            this.min = data.min;
            this.max = data.max;

            this.option_graphe();
            this.option_graphe2();
            this.option_graphe3();
            // this.chartOptions.annotations.points=  data.point_marqueur;

            //console.log(this.chartOptions.annotations.points);
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        recherche: function () {
            if (this.selectannee == '') {
                this.showAlert('Veuillez selectionner une année', 'warning');
                return;
            }
            this.readAll();
        },

        fuseSearchtrimestre: function () {
            this.listmois = [];
            for (var i = 0; i < this.listtrimestre.length; i++) {
                if (this.selecttrimestre == 'T1') {
                    for (var j = 0; j < this.listmois2.length; j++) {
                        if (j <= 2) {
                            this.listmois.push(this.listmois2[j]);
                        }
                    }
                } else if (this.selecttrimestre == 'T2') {
                    for (var j = 0; j < this.listmois2.length; j++) {
                        if (j >= 3 && j <= 5) {
                            this.listmois.push(this.listmois2[j]);
                        }
                    }
                } else if (this.selecttrimestre == 'T3') {
                    for (var j = 0; j < this.listmois2.length; j++) {
                        if (j >= 6 && j <= 8) {
                            this.listmois.push(this.listmois2[j]);
                        }
                    }
                } else if (this.selecttrimestre == 'T4') {
                    for (var j = 0; j < this.listmois2.length; j++) {
                        if (j >= 9 && j <= 11) {
                            this.listmois.push(this.listmois2[j]);
                        }
                    }
                }

            }

        },
        fuseSearchdepartement: function () {
            this.listcommune = this.listcommune2.filter(this.parcourre_commune);
            this.listarrondissement = [];
            this.listvillage = [];

        },
        fuseSearchcommune: function () {
            this.listarrondissement = this.listarrondissement2.filter(this.parcourre_arrondissement);
            this.listvillage = [];

        },

        fuseSearcharrondissement: function () {
            this.listvillage = this.listvillage2.filter(this.parcourre_village);
        },

        parcourre_type_direction: function (obj) {
            if (obj.type_direction == 'DDAEP') {
                return true;
            } else {
                return false;
            }
        },

        parcourre_commune: function (obj) {
            if (obj.id_departements == this.selectdepartement) {
                return true;
            } else {
                return false;
            }
        },
        parcourre_arrondissement: function (obj) {
            if (obj.id_communes == this.selectcommune) {
                return true;
            } else {
                return false;
            }
        },

        parcourre_village: function (obj) {
            if (obj.id_arrondissements == this.selectarrondissement) {
                return true;
            } else {
                return false;
            }
        },

        option_graphe() {
            this.chartOptions = {
                chart: {
                    height: 500,
                    type: 'line',
                    group: 'social',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: true,
                    }
                },
              
                colors: [ '#545454'],
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'CUMUL MOYENNE HAUTEUR DE PLUIE DES PLUIVIOMETRES',
                    align: 'centre',
                    style: {
                        fontSize: '12px',
                        color: '#666'
                    }
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories: ['JANVIER', 'FEVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOUT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DECEMBRE'],
                    title: {
                        text: 'Mois',
                        style: {
                            fontSize: '20px',
                            color: '#666'
                        }

                    }
                },
                yaxis: {
                    title: {
                        text: 'Hauteur(mm) ',
                        style: {
                            fontSize: '20px',
                            color: '#666'
                        }

                    },
                    // min: 5,
                    // max: 40
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5
                },
                
            };
        },

        option_graphe2() {
            this.chartOptions2 = {
                chart: {
                    height: 500,
                    type: 'line',
                    group: 'social',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: true,
                    }
                },
              
                colors: ['#239A1D'],
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'CUMUL NOMBRE DE JOUR DE PLUIE',
                    align: 'centre',
                    style: {
                        fontSize: '12px',
                        color: '#666'
                    }
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories: ['JANVIER', 'FEVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOUT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DECEMBRE'],
                    title: {
                        text: 'Mois',
                        style: {
                            fontSize: '20px',
                            color: '#666'
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: 'Nombre de jour de pluie',
                        style: {
                            fontSize: '20px',
                            color: '#666'
                        }

                    },
                    // min: 5,
                    // max: 40
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5
                },
               
            };
        },

        option_graphe3() {
            this.chartOptions3 = {
                chart: {
                    height: 500,
                    type: 'line',
                   
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: true,
                    }
                },
               
               // colors: ['#77B6EA', '#545454'],
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'CUMUL HAUTEUR DE PLUIE DES PLUIVIOMETRES',
                    align: 'centre',
                    style: {
                        fontSize: '12px',
                        color: '#666'
                    }
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories: ['JANVIER', 'FEVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOUT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DECEMBRE'],
                    title: {
                        text: 'Mois',
                        style: {
                            fontSize: '20px',
                            color: '#666'
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: 'Hauteur(mm) ',
                        style: {
                            fontSize: '20px',
                            color: '#666'
                        }

                    },
                    // min: 5,
                    // max: 40
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5
                },
                /*tooltip: {
                  custom: function({series, seriesIndex, dataPointIndex, w}) {
    return '<div class="arrow_box">' +
      '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
      '<span>' + w.globals.labels[dataPointIndex] + '</span>' +
      
      '</div>'
  }
                }*/
            };
        },

    },

};
</script>
